<template>
  <div>
    <h3 class="flix-html-h3">{{ $t('message.email') }} ({{ $t('message.isRequired') }})</h3>
    <div class="flix-form-group">
      <input type="email" spellcheck="false" v-model="email" class="flix-form-control" :placeholder="$t('message.add', {name: $t('message.email')})" />
    </div>
    <small class="flix-html-small flix-text-info"><flixIcon :id="'info-sign'" /> {{ $t('message.notifyMeViaEmail') }}</small>
  </div>
</template>
<script>
export default {
  components: {},
  props: {
    data: Object,
    callback: Function
  },
  data () {
    return {
      email: this.data.email
    }
  },
  watch: {
    email (val) { this.callback(val.toLowerCase().trim()) }
  },
  methods: {

  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
